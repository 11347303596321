import { defineStore } from 'pinia'

import useActions from './actions'
import { type StateUserType } from './types'

export const useUserStore = defineStore('user', () => {
  const { userDetail }: any = useAuthStorage()
  const processingStates = ['PENDING', 'PROFESSION', 'DOB_AND_REASON']

  const state: StateUserType = reactive({
    userDetail: {
      data: userDetail ?? null,
      isLoading: false
    },
    isProcessingState:
      processingStates?.includes(userDetail?.value?.onBoardingState) ?? false,
    studiSaya: {
      data: null,
      isLoading: false
    }
  })

  const actions = useActions({
    state,
    processingStates
  })
  return {
    state,
    ...actions,
    processingStates
  }
})
