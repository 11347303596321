import { useMessageStore } from '../message'
import {
  type PaylaodValidateEmailType,
  type PayloadOnboardingType,
  type PayloadStudiSayaType,
  type PayloadUpdateEmailType,
  type PayloadUpdatePasswordType,
  type PayloadUpdateProfileType,
  type StudiSayaType
} from './types'
import type { BaseResponse } from '~~/types/contracts/base'
import { type ActionParams } from 'types/storeTypes'
import { useAuthStore } from '~~/stores/auth'

const useActions = ({ state, processingStates }) => {
  const { API_URL } = useBaseUrl()
  const authStorage = useAuthStorage()
  const authStore = useAuthStore()
  const messageStore = useMessageStore()
  const { handleErrorInfo } = useCustomFetch()

  const token = computed(() => authStore?.state?.user?.token)

  const updateUserOnBoarding = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadOnboardingType>) => {
    const { data, error } = await useLazyFetch(
      `${API_URL}/users/on-boarding/${payload?.userSerial}`,
      {
        method: 'put',
        body: payload.data,
        headers: {
          Authorization: token.value
        }
      }
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'updateUserOnBoarding')
    }
    resolve()
  }

  const getUserDetail = async ({ resolve = () => null }: ActionParams<any>) => {
    const { data, error, pending } = await useAsyncData('userDetail', () =>
      $fetch(`${API_URL}/me`, {
        method: 'get',
        headers: {
          Authorization: token.value
        }
      })
    )

    state.userDetail.isLoading = pending?.value ?? false
    if (!data.value && error.value) {
      handleErrorInfo(error, 'getUserDetail')
    }
    const detail: any = useResult(data?.value, 'data', null)
    authStorage.setUserDetail(detail)
    state.userDetail = { data: detail, isLoading: false }
    state.isProcessingState =
      processingStates?.includes(detail?.onBoardingState) ?? false
    resolve()
  }

  const updateUserProfile = async ({
    payload,
    resolve,
    reject
  }: ActionParams<PayloadUpdateProfileType>) => {
    const { data, error } = await useAsyncData('updateProfile', () =>
      $fetch(`${API_URL}/users/profile`, {
        method: 'put',
        body: payload,
        headers: {
          Authorization: token.value
        }
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'updateUserProfile')
      reject()
    }
    resolve()
  }

  const updateUserPassword = async ({
    payload,
    resolve,
    reject
  }: ActionParams<PayloadUpdatePasswordType>) => {
    const { data, error } = await useAsyncData('updateProfile', () =>
      $fetch(`${API_URL}/users/password`, {
        method: 'put',
        body: payload,
        headers: {
          Authorization: token.value
        }
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'updateUserPassword')
      reject()
    }
    resolve()
  }

  const updateUserEmail = async ({
    payload,
    resolve,
    reject
  }: ActionParams<PayloadUpdateEmailType>) => {
    const { data, error } = await useAsyncData('updateProfile', () =>
      $fetch(`${API_URL}/users/email`, {
        method: 'put',
        body: payload,
        headers: {
          Authorization: token.value
        }
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'updateUserEmail')
      reject()
    }
    resolve()
  }

  const fetchStudiSaya = async ({
    payload
  }: ActionParams<PayloadStudiSayaType>) => {
    state.studiSaya.isLoading = true
    try {
      const res: BaseResponse<StudiSayaType[]> = await $fetch(
        `${API_URL}/courses/users`,
        {
          headers: {
            Authorization: token?.value ?? ''
          },
          params: payload?.params
        }
      )
      state.studiSaya = {
        data: res?.data,
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      handleErrorInfo(error, 'fetchStudiSaya')
    } finally {
      state.studiSaya.isLoading = false
    }
  }

  const validateUserEmail = async ({
    payload,
    resolve,
    reject
  }: ActionParams<PaylaodValidateEmailType>) => {
    const { data, error } = await useAsyncData('updateProfile', () =>
      $fetch(`${API_URL}/update-user-email`, {
        method: 'POST',
        body: payload
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'validateUserEmail')
      reject()
    }
    resolve()
  }

  return {
    updateUserOnBoarding,
    getUserDetail,
    updateUserProfile,
    updateUserPassword,
    updateUserEmail,
    fetchStudiSaya,
    validateUserEmail
  }
}

export default useActions
